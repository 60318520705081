import React from "react"

import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"

import "./Twitter.css"

export type TwitterProps = React.SVGAttributes<SVGElement>

export const Twitter = React.memo(function (props: TwitterProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="30"
      viewBox="0 0 36 30"
      className={TokenList.join(["icon-twitter", props.className])}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fillRule="nonzero"
          d="M36 3.67c-1.278.647-2.77 1.078-4.26 1.078 1.49-.863 2.769-2.374 3.195-4.1-1.491.863-2.982 1.51-4.686 1.726C28.97.864 27.053 0 24.923 0c-4.047 0-7.456 3.453-7.456 7.554 0 .647 0 1.08.213 1.727-6.177-.216-11.716-3.238-15.337-7.77a7.527 7.527 0 0 0-1.065 3.885c0 2.59 1.278 4.964 3.408 6.259-1.278 0-2.343-.432-3.408-.864 0 3.67 2.556 6.691 5.965 7.338-.64.216-1.279.216-1.918.216-.426 0-.852 0-1.49-.216.851 3.022 3.62 5.18 7.029 5.18-2.556 1.943-5.752 3.238-9.16 3.238H0C3.195 28.705 7.243 30 11.29 30c13.633 0 21.089-11.439 21.089-21.367V7.554C33.657 6.691 34.935 5.18 36 3.669z"
        />
      </g>
    </svg>
  )
})
